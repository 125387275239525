import React, {Component} from "react";
import Fade from "react-reveal";
import { Parallax } from 'react-scroll-parallax';

import makeCarousel from 'react-reveal/makeCarousel';
import Slide from "react-reveal";
import { Hidden, Visible } from "react-grid-system";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { selector: true, interval: null };
    // this.handleClick = this.handleClick.bind(this);
  }

  componentWillUnmount() {
    // console.log('Component unmounted....')
    clearInterval(this.state.interval)
  }

  render() {
    // console.log("Header data: ", this.props.data)
    if (!this.props.data) return null;

    const networks = this.props.data.social.map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });

    const dateText = this.props.data.date;
    const placeText = this.props.data.place;
    const description = this.props.data.description;

    const CarouselUI = ({ children }) => (
        <>{children}</>
    );
    const Carousel = makeCarousel(CarouselUI);

    return (
        <header id="home" style={{ height: '100vh' }}>
          <nav id="nav-wrap" style={{ display: "flex", backgroundColor: 'black', minHeight: '7vw', width: 'full', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '5%', paddingRight: '5%' }}>
            <div className="nav-logo">
              <picture>
                <img style={{ width: 60, height: 60, objectFit: "contain" }}  src="/images/marchio_white.webp" alt="marchio"/>
              </picture>
              <Visible md lg xl xxl xxxl>
                <div style={{width: "35vw", height: "50px", position: "relative"}}>
                  <Carousel defaultWait={2500} maxTurns={Infinity}>
                    <Fade top>
                      <h4 style={{ color: '#7e7e7e', fontSize: "1.5vw" }}>{dateText}</h4>
                    </Fade>
                    <Fade top>
                      <h4 style={{ color: '#7e7e7e', fontSize: "1.5vw" }}>{placeText}</h4>
                    </Fade>
                  </Carousel>
                </div>
                </Visible>
            </div>

            <ul className="social-links">{networks}</ul>

            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
              Show navigation
            </a>
            <a className="mobile-btn" href="#home" title="Hide navigation">
              Hide navigation
            </a>

            <ul id="nav" className="nav">
              <li className="current">
                <a className="smoothscroll" href="#home">
                  Home
                </a>
              </li>

              <li>
                <a className="smoothscroll" href="#about">
                  About
                </a>
              </li>

              <li>
                <a className="smoothscroll" href="#resume">
                  Servizi
                </a>
              </li>

              <li>
                <a className="smoothscroll" href="#contest">
                  Contest
                </a>
              </li>

              <li>
                <a className="smoothscroll" href="#portfolio">
                  Dov'è
                </a>
              </li>
            </ul>

          </nav>

            <div className="row banner">
              <div className="banner-text">
                <Fade bottom style={{ zIndex: 99 }}>
                  {/*<h1 className="responsive-headline">{name}</h1>*/}
                  <Parallax translateY={[-60, 20]} speed={10}>
                    <picture>
                      <img src={'/images/logo_white.webp'} alt={'logo'} />
                    </picture>
                  </Parallax>
                </Fade>
                <Fade bottom duration={1200}>
                  <Parallax opacity={[1, 0.6]}>
                    <h2 style={{ color: 'white' }} >{description.toUpperCase()}.</h2>
                  </Parallax>
                </Fade>
                <hr />
                <Fade bottom duration={2000} style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', zIndex: -99 }}>
                  <Parallax opacity={[1, 0.8]} speed={10} style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <a className="smoothscroll" href="#ticketSms" style={{ textDecoration: 'none' }}>
                      <button className="button-40" role="button">
                        <span>Acquista il biglietto</span>
                      </button>
                    </a>
                  </Parallax>
                </Fade>
              </div>
            </div>

          <p className="scrolldown">
            <a className="smoothscroll" href="#about">
              <i className="icon-down-circle"></i>
            </a>
          </p>
        </header>
    );
  }
}

export default Header;
