import React, { Component } from "react";

class ScrollText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerWidth: '100%',
      animationState: 'paused',
    };
    this.ref = React.createRef();
    // this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.ref.current) {
      this.setState({
        containerWidth: this.ref.current.scrollWidth + "px",
        animationState: "running"
      });
    }
  }
  render() {
    return (
      <div className="scroll-text">
        <div
          className="d-flex"
          ref={this.ref}
          style={{
            width: `100%`,
            animationPlayState: this.state.animationState,
          }}
        >
          <h4
            className="scroll-text-content"
          >
            {`${this.props.text}`}
          </h4>
        </div>
      </div>
    );
  }
}

export default ScrollText;
