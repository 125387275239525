import React, { Component } from "react";
import Fade from "react-reveal";
import Slide from "react-reveal";
import { Container, Row, Col, Hidden } from 'react-grid-system';

class About extends Component {
  render() {
    const profilepic = "images/about_vertical.jpeg";
    const bio1 = this.props.data.bio1;
    const bio2 = this.props.data.bio2;
    const bio3 = this.props.data.bio3;

    return (
      <section id="about">
        <Container fluid style={{ background: "none" }}>
          <Row justify="center" style={{ marginBottom: '3vw' }}>
            <Col sm={6} justify="center" align="center">
              <Fade duration={1000}>
                <picture>
                  <img
                    className="profile-pic"
                    src={profilepic}
                    alt="about"
                  />
                </picture>
              </Fade>
            </Col>
            <Col sm={6} xs={12}>
              <Fade duration={1000}>
                <div className="row">
                  <div className="nine columns main-col">
                  <h2>Su di noi</h2>
                    <Slide left duration={1300}>
                      <p>{bio1}</p>
                    </Slide>
                    <Slide right duration={1300}>
                      <p>{bio2}</p>
                    </Slide>
                    <Slide left duration={1300}>
                      <p>{bio3}</p>
                    </Slide>
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default About;
