import React, { Component } from "react";
import Slide from "react-reveal";
import { Parallax } from 'react-scroll-parallax';

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const skillmessage = this.props.data.skillmessage;
    const { foodDescription, tokenDescription, previousEvents } = this.props.data

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row food">
            <div className="three columns header-col">
              <h1>
                <span>Area Food</span>
              </h1>
              <Parallax speed={5}>
                <picture>
                  <img src={foodDescription.imgPath} alt={'descFood'} style={{ boxShadow: '5px 5px 5px 0px rgb(84,84,84,0.3)', zIndex: 99 }} />
                </picture>
              </Parallax>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns description-resume">{foodDescription.text}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide right duration={1300}>
          <div className="row food">
            <div className="three columns header-col">
              <h1>
                <span>Token</span>
              </h1>
              <Parallax speed={8}>
                <picture>
                  <img src={tokenDescription.imgPath} alt={'descToken'} style={{ boxShadow: '5px 5px 5px 0px rgb(84,84,84,0.3)', zIndex: 99 }} />
                </picture>
              </Parallax>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns description-resume">{tokenDescription.text}</div>
              </div>
            </div>
          </div>
        </Slide>

        {/* <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Eventi passati</span>
              </h1>
              <Parallax speed={10}>
                <picture>
                  <img src={previousEvents.imgPath} alt={'descEvents'} />
                </picture>
              </Parallax>
            </div>

            <div className="nine columns main-col">
              <p>{skillmessage}</p>

              <div className="bars">
                <ul className="skills">{previousEvents.text}</ul>
              </div>
            </div>
          </div>
        </Slide> */}
      </section>
    );
  }
}

export default Resume;
