import React, { Component } from "react";
import Fade from "react-reveal";
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { Parallax } from 'react-scroll-parallax';

class Portfolio extends Component {
  render() {
    // console.log('Desc: ', this.props)
    return (
      <section id="portfolio">
        <Container fluid style={{ background: "none" }}>
          <Row justify="center" style={{ marginBottom: '3vw' }}>
            <h2
              className="portfolio-description"
            >
              {this.props?.data?.description}
            </h2><br />
            <h2
            className="portfolio-subtitle"
            >
              Sua maestà l’ETNA
            </h2>
          </Row>
          <Row>
            <Col sm={6}>
              <Fade left duration={1000} distance="40vh">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                  <h2
                    className="portfolio-map-title"
                  >
                    Come raggiungerci
                  </h2>
                  <div
                    id="map-container"
                  >
                      <iframe
                        width="70%"
                        height="70%"
                        id="gmap_canvas"
                        style={{filter: `blur()`, border: 0}}
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d468.76884159093396!2d15.010059212613806!3d37.62349260040009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sit!2sit!4v1691400695217!5m2!1sit!2sit"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        title="googleMap"
                        >

                        </iframe>

                    {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid"
                    width="500"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  /> */}
                  </div>
                </div>
              </Fade>
            </Col>
            <Hidden xs sm>
              <Col sm={6} style={{ paddingLeft: '8vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Fade right duration={3000} distance="90vh">
                  <div
                    id="portfolio-place"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      height: "80%",
                      width: "90%",
                    }}
                  >
                    <Parallax speed={20} style={{ width: '100%', height: '100%' }}>
                      <picture>
                        <img src="/images/etna.webp" style={{ width: '100%', height: '100%' }} alt="place" />
                      </picture>
                    </Parallax>
                    <Fade right duration={8000} distance="90vh">
                      <picture style={{ position: "absolute", left: '-10vw', width: '90%' }} >
                        <img src="/images/testBG.webp" alt="etna"/>
                      </picture>
                    </Fade>
                  </div>
                </Fade>
              </Col>
            </Hidden>
          </Row>
        </Container>
        {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>


        </div> */}
        {/* <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of My Works.</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade> */}
      </section>
    );
  }
}

export default Portfolio;
