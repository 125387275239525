import { Component } from "react";

class Section extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            wait: this.props.wait || 0
        }
    }
    
    show = () => {
        this.setState({hidden : ""});
    }

    componentDidMount = () => {
        const that = this;
        setTimeout(function() {
            that.show();
        }, this.props.wait);
    }

    render() {
        return(
            <section id={this.props.id} className={this.state.hidden}>
                {this.props.children}
            </section>
        )
    }
}

export default Section;