import React, { Component } from "react";
import ReactGA from "react-ga";
import "./App.css";
import "@fontsource/krona-one";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Spotify from "./Components/Spotify";
import Portfolio from "./Components/Portfolio";
import CountdownPage from "./Components/CountdownPage";
import TicketSms from "./Components/TicketSms";
import GifPage from "./Components/GifPage";
import Contest from "./Components/Contest";
import * as data from './data/resumeData.json';
import PreviewPage from "./Components/PreviewPage";

class App extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  // componentDidMount() {
  //     console.log("Data: ", data)
  // }

  render() {
    return (
      <div className="App">
        <PreviewPage data={data.main} />
        <TicketSms wait={5000} />
        <Spotify />
        <Portfolio data={data.place}/>
        <Footer data={data.main} />
        {/*<Header data={data.main} />
        <About data={data.main} />
        <TicketSms wait={5000} />
        <GifPage />
        <Resume data={data.resume} />
        <Contest data={data.contest} />
        <CountdownPage />
        <Spotify />
        <Portfolio data={data.place}/>
        <Footer data={data.main} />*/}
      </div>
    );
  }
}

export default App;
