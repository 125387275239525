import React, { Component } from "react";

class GifPage extends Component {
    
    render() {
        return (
            <section id="gifPage">
                <div
                    className="parallax"
                    style={{
                        minHeight: '65vh',
                        width: '100%',
                        backgroundImage: `url(/images/headerGif.gif)`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                />
            </section>
        )
    }
}

export default GifPage;