import React, { Component } from "react";
import Slide from "react-reveal";
import { Parallax } from 'react-scroll-parallax';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';

class Spotify extends Component {

  constructor(props) {
    super(props);
    this.state = {
     speed: 9
    }
   }

  render() {
    return (
      <section id="spotify">

        <Container fluid>
          <Row justify="center" align="center" style={{ marginBottom: '5vh', paddingLeft: '10%', paddingRight: '10%' }}>
            <Col xs={12} sm={6} md={6}>
              <ScreenClassRender render={screenClass => (
                <div style={{ display: "flex", justifyContent: ['lg', 'xl', 'xxl'].includes(screenClass) ? 'flex-start' : 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                  <picture>
                    <img style={{ maxWidth: '60%', animation: `spin ${this.state.speed}s linear infinite` }}  src="/images/vulkano_records_logo.webp" alt="vulkano_records_marchio"/>
                  </picture>
                </div>
              )} />
            </Col>
            <Col xs={12} sm={6} md={6} justify="center" >
            <p>  Vera e propria etichetta discografica che offre la possibilità di raggiungere un’audience ampia e diversificata tramite copertura integrata sulle piattaforme social e di settore</p>
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: '3vw' }}>
            <Slide left duration={1300}>
              <div className="row" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
              <iframe
                style={{ borderRadius: "12px" }}
                // src="https://open.spotify.com/embed/album/4z6S5V0ulevAA5rPEiMxnv?utm_source=generator"
                src="https://open.spotify.com/embed/album/3dJmcs3reyoCkG3jQ2u7vg?si=HZoHvm0XRmCvISL234fYMA"
                width="100%"
                height="352"
                frameBorder="0"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy">
              </iframe>
              </div>
            </Slide>
          </Row>
          <Row justify="center" align="center" style={{ marginBottom: '3vw' }}>
            <Slide right duration={1500}>
              <a
                style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '3vh' }}
                href="https://linktr.ee/vulkano.records?utm_source=linktree_profile_share&ltsid=edc48382-3541-4c64-8fe2-57ff42a01965"
              >
                <button style={{ background: 'none', width: '20%', height: '2vh' }} >
                  <img  src="/images/linktree-logo.webp" alt="linktree"/>
                </button>
              </a>
            </Slide>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Spotify;
