import React, { Component } from 'react';
import {Parallax} from "react-scroll-parallax";
import {Fade, Slide, AttentionSeeker} from "react-awesome-reveal";
import Countdown from "react-countdown";
import ScrollText from "./ScrollText";

const Completionist = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      fontSize: "2.2rem"
    }}>
      <h1 style={{color: 'whitesmoke'}}>
        10 Agosto 2024
      </h1>
    </div>
  );
};

class CountDownElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      label: this.props.label
    };
  }

  render() {
    return (
      <div className="countdown-element">
        <p>{this.props.value}</p>
        <hr/>
        <p className="label">{this.props.label}</p>
      </div>
    );
  }
}

const Colon = () => {
  return (
    <p className="colon">:</p>
  );
}

const renderer = ({days, hours, minutes, seconds, completed}) => {
  if (completed) {
    return <Completionist/>;
  } else {
    return (
      <div className="countdown-renderer">
        <CountDownElement value={days} label="days"/>
        <Colon/>
        <CountDownElement value={hours} label="hours"/>
        <Colon/>
        <CountDownElement value={minutes} label="minutes"/>
        <Colon/>
        <CountDownElement value={seconds} label="seconds"/>
      </div>
    );
  }
};

class PreviewPage extends Component {

  constructor(props) {
    super(props);
    this.state = { selector: true, interval: null };
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  render() {

    return (
      <header id="home" style={{ height: '100vh' }}>
        <nav
          id="nav-wrap"
          style={{
            display: "flex",
            backgroundColor: '#C61B19',
            minHeight: '4vw',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <ScrollText text="  10 AGOSTO   -   ETNA, PINETA MONTI ROSSI                                                             10 AGOSTO   -   ETNA, PINETA MONTI ROSSI   "/>
        </nav>
        <div className="row banner">
          <div className="banner-text">
            <Slide direction="down" duration={500} style={{zIndex: 99}}>
              <Parallax translateY={[-60, 20]} speed={6}>
                <picture>
                  <img
                    style={{width: window.innerWidth < 768 ? '150%' : '70%'}}
                    src={'/images/logo_white.webp'}
                    alt={'logo'}
                  />
                </picture>
              </Parallax>
            </Slide>
            <AttentionSeeker effect="heartBeat" style={{zIndex: 99}}>
              <img
                src={'/images/Star.png'}
                alt={'logo'}
              />
            </AttentionSeeker>
            <Slide direction="up" duration={500} style={{zIndex: 99, marginTop: '9vh'}}>
              <Parallax translateY={[-60, 20]} speed={10}>
                <Countdown date={new Date("2024-08-10T19:30:00.956Z")} renderer={renderer} />
              </Parallax>
            </Slide>
          </div>
        </div>
      </header>
    );
  }
}

export default PreviewPage;
