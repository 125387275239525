import { Component } from "react";
import Slide from "react-reveal";
import Section from "./Section";
import {Parallax} from "react-scroll-parallax";


class TicketSms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iFrameLoaded: false
        }
      }

    componentDidMount() {
        const that = this
        const iframe = document.getElementById("ticketIframe");
        iframe.addEventListener("load", function() {
            that.setState({ iFrameLoaded: true })
        });
    }

    componentWillUnmount() {
        this.setState({ iFrameLoaded: false })
    }

    render() {
        return(
            <Section id="ticketSms" wait={this.props.wait}>
                <Slide left duration={1300} style={{zIndex: 99}}>
                  <Parallax translateY={[-60, 20]} speed={50}>
                    <h2>Acquista i biglietti</h2>
                  </Parallax>
                </Slide>
                <div style={{ height: '5vh' }}/>
                <iframe
                    id="ticketIframe"
                    src="https://www.ticketsms.it/event/Vulkano-Summit-Festival-Nicolosi-Etna-10-08-2024/iframe"
                    title="Acquista i biglietti"
                    height="90%"
                    width="80%"
                    style={{borderRadius: "40px", boxShadow: '1px 2px 9px #c1272c79'}}
                >
                </iframe>
                {!this.state.iFrameLoaded && (
                    <Slide right duration={1300}>
                        <a href="https://www.ticketsms.it/event/Vulkano-Summit-Festival-Nicolosi-Etna-10-08-2024" style={{ textDecoration: 'none' }}>
                            <button className="button-40" role="button">
                                <span>Vai su TicketSMS</span>
                            </button>
                        </a>
                    </Slide>
                )}
            </Section>
        );
    }
}

export default TicketSms;
