import React, { Component } from "react";
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import Fade from "react-reveal";
import { Parallax } from 'react-scroll-parallax';

class Contest extends Component {

    constructor(props) {
        super(props);
        // this.state = { description: [] };
    }

    downloadFile() {
        fetch('regolamento_contest.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Regolamento Contest VulkanoSummitFestival.pdf';
                alink.click();
            })
        })
    }

    render() {
        return (
            <section id="contest">
                <Container fluid style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%', minHeight: '100vh' }}>
                    <Row justify="center" align="center">
                        <h1 className="contest-title">DJ Contest</h1>
                    </Row>
                    <Row justify="center" align="center">
                        <Col sm={12} xs={12} md={6} justify="center" align="center" style={{ zIndex: 99 }}>
                            <Fade left duration={1500} distance="90vh">
                                <Parallax speed={20}>
                                    <img style={{ maxHeight: '55vh', boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.3)' }} src="/images/contest.webp" alt="Contest"/>
                                </Parallax>
                            </Fade>
                        </Col>
                        <Col sm={12} xs={12} md={6} style={{ paddingRight: '10%', paddingLeft: '10%'}}>
                            {!this.props.data ? (
                              <>
                                <Row justify="center" align="center" className="contest-row">
                                    <Fade right duration={1500} distance="90vw">
                                        <span>
                                            La Mission di VULKANO non punta solo a portare i migliori artisti della scena dance internazionale sull’ETNA, ma vuole anche dare la possibilità a giovani talenti dj e producers locali di far conoscere il loro sound ad un pubblico vasto ed appassionato del genere.
                                        </span>
                                    </Fade>
                                </Row>
                                <Row justify="center" align="center" className="contest-row">
                                    <Fade right duration={3000} distance="200vw">
                                        <span>
                                            VULKANO contest, che ogni anno vede sfidarsi decine di artisti a suon di mix per conquistarsi il diritto di esibirsi sul main stage per far saltare migliaia di giovani dancers della movida Etnea;
                                        </span>
                                    </Fade>
                                </Row>
                              </>
                            ) : (
                                this.props.data?.description?.map((txt, idx) =>
                                    <Row justify="center" align="center" className="contest-row">
                                        <Fade right duration={1500 * (idx+1)} distance={`${100*(idx+1)}vw`}>
                                            <span>
                                                {txt}
                                            </span>
                                        </Fade>
                                    </Row>
                                )
                            )}

                        </Col>
                    </Row>
                  {/*@todo: add download button*/}
                    {/*<Row justify="center" align="center" style={{ paddingRight: '10%', paddingLeft: '10%' }}>
                        <Fade bottom duration={1500} distance="90vh">
                            <button className="button-40" role="button" onClick={() => this.downloadFile()}  style={{ paddingRight: '5%', paddingLeft: '5%' }}>
                                <span>Scarica il regolamento</span>
                            </button>
                        </Fade>
                    </Row>*/}

                </Container>
            </section>
        )
    }
}

export default Contest;
