import React, { Component } from "react";
import Countdown from "react-countdown";
import {Col, Row} from "react-grid-system";
import { Parallax } from 'react-scroll-parallax';

const Completionist = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      fontSize: "2.2rem"
    }}>
      <h1 style={{color: 'whitesmoke'}}>
        10 Agosto 2024
      </h1>
    </div>
  );

};

const renderer = ({days, hours, minutes, seconds, completed}) => {
  if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', fontSize: "3.2rem" }}>
          <div id="days" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: '15%' }}>
              <h1 style={{ color: 'whitesmoke', marginRight: '4%' }}>{days}</h1>
              <h1 style={{ color: 'red', marginLeft: '4%' }}>g</h1>
          </div>
          <div id="hours" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: '2%' }}>
            <h1 style={{ color: 'whitesmoke', marginRight: '4%' }}>
              {hours}:{minutes}:{seconds}
            </h1>
            <h1 style={{ color: 'red', marginLeft: '4%' }}>h</h1>
          </div>
        </div>
      );
    }
  };

class CountdownPage extends Component {

    constructor(props) {
        super(props);
        this.Ref = React.createRef();
        this.state = { timer: '00:00:00' };
      }

    render() {
        return (
            <section id="countdown">
              <div
                  className="parallax"
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    // backgroundImage: `url(/images/headerGif.gif)`,
                    backgroundImage: `url(/images/sfondo_countdown.png)`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    minHeight: "65vh",
                    width: "100%"
                  }}
                >
                  <Parallax translateY={[-60, 20]} speed={10}>
                    <Countdown date={new Date("2024-08-10T19:30:00.956Z")} renderer={renderer} />
                  </Parallax>
              </div>
            </section>
        );
    }
}

export default CountdownPage;
