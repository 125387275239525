import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    const networks = this.props.data.social.map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;
    const associationInfo = this.props.data.association;

    return (
      <footer>
        <div className="row">
          <div id="go-top">
              <a className="smoothscroll" title="Back to Top" href="#home">
                <i className="icon-up-open"></i>
              </a>
          </div>
          <Fade bottom>
            <div className="twelve columns">
              <ul className="social-links">{networks}</ul>

              <div className="columns contact-details">
                  <p className="address">
                    {/* <span>
                      {street}, {city}, {state}
                    </span> */}
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                    <br />
                  </p>
                  <p className="copyright">
                    <span>&copy; Copyright {associationInfo?.name} {associationInfo?.since}</span>
                    <br />
                    <span style={{ fontStyle: 'italic' }}>CF: {associationInfo?.CF}, VAT: {associationInfo?.VAT}</span>
                    <br />
                    <span style={{ fontStyle: 'italic' }}>{associationInfo?.fullAddress}</span>
                  </p>
              </div>
            </div>
          </Fade>
        </div>
      </footer>
    );
  }
}

export default Footer;
